import type { HourType } from '@/types';
import { type ClassValue, clsx } from 'clsx';
import { isBefore, isSameDay } from 'date-fns';
import { addDays, format, getHours, getMinutes, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import getUserLocale from 'get-user-locale';
import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { UUID } from 'uuidjs';

export const genUUIDv4 = () => {
	return UUID.genV4().hexString;
};

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function sleep(ms: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}

export function getRandomArrayItem<T>(array: T[]): T {
	return array[Math.floor(Math.random() * array.length)];
}

export function shuffleArray<T>(array: T[]): T[] {
	let currentIndex = array.length;
	let randomIndex: number;

	// While there remain elements to shuffle.
	while (currentIndex !== 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}

	return array;
}

export const createUserShell = (uuid: string = genUUIDv4(), email?: string) => {
	const locale = getUserLocale();
	return {
		uuid,
		locale,
		...(email ? { email } : {}),
	};
};

export function getTip() {
	const tips = [
		'We recommend tools like Figma to complete the challenge, it is versatile, powerful and free to use.',
		'Use tools like shots.so to prettify your screenshots and share your work with the community.',
		'Share your work on platforms like Twitter, Behance, Dribbble, etc. to get feedback from the community.',
		'Practice makes perfect! Try doing little design tasks every day to sharpen your skills.',
		"Use references for your designs. Look at famous designers' projects online for new ideas and trends in UI design.",
	];
	return getRandomArrayItem(tips);
}

type EmailType = {
	to: string[];
	from: string;
	subject: string;
	text: string;
	react?: ReactNode;
};

export const batchArray = (array: EmailType[], batchSize: number) => {
	const result = [];
	for (let i = 0; i < array.length; i += batchSize) {
		const batch = array.slice(i, i + batchSize);
		result.push(batch);
	}
	return result;
};

const cronTime = '0 17 * * *';
export const getNextRunTime = (lastSend?: string | null): string => {
	const [minute, hour] = cronTime.split(' ').map(Number);
	const now = new Date();

	// Convert current time to UTC
	const nowUTCString = now.toISOString();
	const nowUTC = parseISO(nowUTCString);

	let nextRunDateUTC: Date | string;

	if (
		getHours(nowUTC) > hour ||
		(getHours(nowUTC) === hour && getMinutes(nowUTC) >= minute)
	) {
		// If current UTC time is past the cron time, set next run to tomorrow
		nextRunDateUTC = addDays(nowUTC, 1);
	} else {
		nextRunDateUTC = nowUTC;
	}

	nextRunDateUTC.setUTCHours(hour, minute, 0, 0);

	// Convert UTC next run date to local timezone for display
	const nextRunDateLocal = utcToZonedTime(
		nextRunDateUTC,
		Intl.DateTimeFormat().resolvedOptions().timeZone,
	);

	let lastSendUTC: Date | null = null;
	if (lastSend) {
		lastSendUTC = utcToZonedTime(
			lastSend,
			Intl.DateTimeFormat().resolvedOptions().timeZone,
		);
	}

	// Check if the current time is before the next run date.
	if (isBefore(now, nextRunDateLocal)) {
		// If lastSendUTC is not set or it's a different day than lastSendUTC, schedule for today.
		if (!lastSendUTC || !isSameDay(nowUTC, lastSendUTC)) {
			return `Today at ${format(nextRunDateLocal, 'haaa')}`;
		}
	}

	// If none of the above conditions are met, schedule for tomorrow.
	return `Tomorrow at ${format(nextRunDateLocal, 'HH:mm')}`;
};

export function convertUserHourToUTC(
	userHour: HourType,
	userTimezone: string,
): number {
	const userHourInt = Number.parseInt(userHour, 10);

	// Create a date object for the current date at the user's hour in their timezone
	const userDate = new Date(0); // Start with a date at Unix epoch
	const zonedDate = utcToZonedTime(userDate, userTimezone);
	zonedDate.setHours(userHourInt, 0, 0, 0); // Set the hours, minutes, seconds, and milliseconds

	// Convert the user's date to UTC
	const utcDate = zonedTimeToUtc(zonedDate, userTimezone);
	const utcHour = utcDate.getUTCHours();
	return utcHour;
}

export function convertUTCToUserHour(utcHour: number, userTimezone: string) {
	const utcDate = new Date(0);
	utcDate.setUTCHours(utcHour, 0, 0, 0);
	const zonedDate = utcToZonedTime(utcDate, userTimezone);
	const userHour = zonedDate.getHours();
	return userHour;
}
