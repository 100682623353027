import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';

let INITIALIZED = false;
export namespace Analytics {
	export const init = () => {
		if (!INITIALIZED && process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development') {
			mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string, {
				cross_site_cookie: true,
				api_host: '/mp',
				ignore_dnt: true,
			});

			INITIALIZED = true;
		}
	};

	export const track = (
		event: string,
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		properties?: Record<string, any>,
	): void => {
		if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development') {
			try {
				mixpanel.track(event, {
					...properties,
					'Local Hour': new Date().getHours(),
				});
			} catch (e) {
				console.error('Unable to log Analytics event', e);

				try {
					mixpanel.track('mixpanel.error', {
						error_message: (e as Error).message,
						error: e,
					});
				} catch (err) {
					console.error('Failed to track failed mixpanel event', err);
				}
			}
		} else {
			if (process.env.NODE_ENV !== 'development') {
				console.trace();
			}
			console.info(
				'%c[Analytics] %c%s',
				'color:green',
				'color:gray',
				event,
				properties,
			);
		}
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	export const identify = (uuid: string, properties?: Record<string, any>) => {
		if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development') {
			if (uuid) {
				ReactGA.gtag('set', 'user_properties', {
					crm_id: uuid,
				});
				console.debug('Identifying user', uuid);
				mixpanel.identify(uuid.toString());

				if (properties) {
					mixpanel.people.set_once(properties);
				}
			}
		} else {
			console.info(
				'%c[Analytics] %c%s',
				'color:green',
				'color:gray',
				'identify',
				uuid,
				properties,
			);
		}
	};
}
