import { differenceInMinutes, getTime, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { createJSONStorage } from 'zustand/middleware';
import type { StateType, StoreStateActionsType } from './index';

const storage = createJSONStorage<StoreStateActionsType>(() => ({
	// Returning a promise from getItem is necessary to avoid issues with hydration
	getItem: async (name: string) => localStorage.getItem(name),
	setItem: (name: string, value: string) => localStorage.setItem(name, value),
	removeItem: (name: string) => localStorage.removeItem(name),
}));

export const storeConfig = {
	name: 'uic-store',
	storage,
	version: 1,
};

export const setLoading = (
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	set: any,
	state: StateType,
	sliceKey: keyof StateType,
	loading: boolean,
) => {
	set({
		[sliceKey]: {
			...state[sliceKey],
			loading,
		},
	});
};

export const setSlice = (
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	set: any,
	state: StateType,
	sliceKey: keyof StateType,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	data: Record<string, any>,
) => {
	set({
		[sliceKey]: {
			...state[sliceKey],
			...data,
		},
	});
};

export const getUTCTimestamp = () => {
	const parsedTime = parseISO(new Date().toISOString());
	const formattedTime = utcToZonedTime(parsedTime, 'UTC');
	return getTime(formattedTime);
};

export const checkTimestampExpired = (timestamp: number, minutes: number) => {
	const parsedTimestamp = parseISO(new Date(timestamp).toISOString());
	const parsedTime = parseISO(new Date().toISOString());
	const formattedCurrentTime = utcToZonedTime(parsedTime, 'UTC');

	return differenceInMinutes(formattedCurrentTime, parsedTimestamp) > minutes;
};
