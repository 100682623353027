import { getActiveChallenges } from '@/app/actions/getActiveChallenges';
import { getAsset } from '@/app/actions/getAsset';
import { getAssetsWithUser } from '@/app/actions/getAssetsWithUser';
import { getChallenges } from '@/app/actions/getChallenges';
import { getCheckins } from '@/app/actions/getCheckins';
import { getLikes } from '@/app/actions/getLikes';
import { getUser } from '@/app/actions/getUser';
import { updateUser } from '@/app/actions/updateUser';
import { upsertUser } from '@/app/actions/upsertUser';
import type {
	Asset,
	Challenge,
	CheckIn,
	Like,
	PartialLike,
	User,
} from '@/types';
import { generateUsername } from 'unique-username-generator';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { Analytics } from '../mixpanel';
import {
	checkTimestampExpired,
	getUTCTimestamp,
	setLoading,
	setSlice,
	storeConfig,
} from './utils';

export type CommonSliceStateType = {
	loading: boolean;
	error: Error | null;
	lastCached: number | null;
};

const commonSliceState = {
	loading: false,
	error: null,
	lastCached: null,
};

export type StateType = {
	user: CommonSliceStateType & {
		data: Partial<User> | null;
	};
	challenges: CommonSliceStateType & {
		data: [];
	};
	assets: CommonSliceStateType & {
		data: Asset[];
	};
	activeChallenge: CommonSliceStateType & {
		data: Challenge | null;
	};
	checkIns: CommonSliceStateType & {
		data: CheckIn[];
	};
	likes: CommonSliceStateType & {
		data: Like[];
	};
};

export type ActionsType = {
	fetchLikesDelta: (bypassCache: boolean) => Promise<void>;
	fetchAssetsDelta: (bypassCache: boolean) => Promise<void>;
	fetchChallengesDelta: (userEmail?: string) => Promise<void>;
	fetchActiveChallengeDelta: (
		userEmail?: string,
		bypassCache?: boolean,
	) => Promise<void>;
	fetchUserDelta: (userEmail?: string) => Promise<User | undefined>;
	fetchCheckInsDelta: (
		userEmail?: string,
		bypassCache?: boolean,
	) => Promise<void>;

	// Local updates
	updateAssetLikes: (assetId: number) => void;
	addLike: (like: Like) => void;
	updateUser: (user: Partial<User> & { email: string }) => void;
	upsertUser: (user: Partial<User> & { email: string }) => Promise<User | null>;
	updateLocalUser: (user: Partial<User>) => void;
	updateLocalActiveChallenge: (
		challenge: Partial<Challenge>,
		withCache: boolean,
	) => void;

	// Updates off subscription
	updateAssetLike: (like: PartialLike, type: 'INSERT' | 'DELETE') => void;

	// Cleanup data
	dropCache: (slice: keyof StateType) => void;
	dropSlice: <T>(slice: keyof StateType, defaultData: T) => void;
	clearUser: () => void;
	reset: () => void;
};

export type StoreStateActionsType = StateType & ActionsType;
export type StoreType = ReturnType<typeof createStore>;

export const defaultInitState: StateType = {
	user: {
		...commonSliceState,
		data: {} as Partial<User>,
	},
	challenges: {
		...commonSliceState,
		data: [],
	},
	activeChallenge: {
		...commonSliceState,
		data: {} as Challenge,
	},
	checkIns: {
		...commonSliceState,
		data: [],
	},
	assets: {
		...commonSliceState,
		data: [],
	},
	likes: {
		...commonSliceState,
		data: [],
	},
};

// TODO slice up the store - https://docs.pmnd.rs/zustand/guides/typescript#slices-pattern

export const createStore = (initState: StateType = defaultInitState) =>
	create<StoreStateActionsType>()(
		devtools(
			persist<StoreStateActionsType>(
				(set, get) => ({
					...initState,

					// Off subscription updates
					updateAssetLike: async (like, type) => {
						const likes = get().likes.data;
						const assetId = likes.find((row) => row.id === like.id)?.asset;

						if (!assetId) {
							return;
						}

						const status = await getAsset(assetId);
						if (status?.error || !status?.asset) {
							return;
						}

						set((state) => ({
							assets: {
								...state.assets,
								data: state.assets.data.map((row) => {
									if (row.id === assetId) {
										return {
											...(status.asset as Asset),
										};
									}
									return row;
								}),
							},
							likes: {
								...state.likes,
								data:
									type === 'INSERT'
										? state.likes.data.find(
												(row) =>
													row.asset === like.asset && row.user === like.user,
										  )
											? state.likes.data
											: [...state.likes.data, like as Like]
										: state.likes.data.filter((row) => row.id !== like.id),
							},
						}));
					},

					// Actions
					fetchLikesDelta: async (bypassCache?: boolean) => {
						const state = get();

						// Do nothing if cache is still there
						if (
							state.likes.lastCached &&
							!checkTimestampExpired(state.likes.lastCached, 10) &&
							!bypassCache
						) {
							return;
						}

						// Otherwise fetch likes and set them in the store
						setLoading(set, state, 'likes', true);
						const status = await getLikes();
						if (status?.error || !status?.likes) {
							Analytics.track('likes.get.error', {
								error: status?.error.message,
								length: (status?.likes || [])?.length,
							});
							setSlice(set, state, 'likes', {
								loading: false,
								error: status?.error.message,
							});
							return;
						}

						if (status?.likes?.length > 0) {
							setSlice(set, state, 'likes', {
								data: status.likes,
								loading: false,
								lastCached: getUTCTimestamp(),
							});
						} else {
							setLoading(set, state, 'likes', false);
						}
					},

					fetchAssetsDelta: async (bypassCache?: boolean) => {
						const state = get();

						// Do nothing if cache is still there
						if (
							state.assets.lastCached &&
							!checkTimestampExpired(state.assets.lastCached, 10) &&
							!bypassCache
						) {
							return;
						}

						// Otherwise fetch assets and set them in the store
						setLoading(set, state, 'assets', true);
						const status = await getAssetsWithUser();
						if (status?.error || !status?.assets) {
							Analytics.track('assets.get.error', {
								error: status?.error.message,
								length: (status?.assets || [])?.length,
							});
							setSlice(set, state, 'assets', {
								loading: false,
								error: status?.error.message,
							});
							return;
						}

						if (status?.assets?.length > 0) {
							setSlice(set, state, 'assets', {
								data: status.assets,
								loading: false,
								lastCached: getUTCTimestamp(),
							});
						} else {
							setLoading(set, state, 'assets', false);
						}
					},

					updateAssetLikes: (assetId: number) => {
						const assets = get().assets.data;
						const localLikes = get().likes.data;
						const user = get().user.data;

						const existingLike = localLikes.find(
							(row) => row.asset === assetId && row.user === user?.email,
						);
						const existingLikesForAsset = assets.find(
							(row) => row.id === assetId,
						)?.likes;

						const updatedLikes = existingLike
							? (existingLikesForAsset || 0) - 1
							: (existingLikesForAsset || 0) + 1;

						set((state) => ({
							assets: {
								...state.assets,
								data: state.assets.data.map((row) => {
									if (row.id === assetId) {
										return {
											...row,
											likes: updatedLikes,
										};
									}
									return row;
								}),
							},
						}));
					},

					fetchChallengesDelta: async (userEmail?: string) => {
						const state = get();
						const email = state.user.data?.email || userEmail;

						// Do nothing if user email is not set yet
						if (!email) {
							return;
						}

						// Do nothing if cache is still there
						if (
							state.challenges.lastCached &&
							!checkTimestampExpired(state.challenges.lastCached, 10)
						) {
							return;
						}

						// Otherwise fetch challenges and set them in the store
						setLoading(set, state, 'challenges', true);
						const status = await getChallenges(email);
						if (status?.error || !status?.challenges) {
							Analytics.track('challenges.get.error', {
								email,
								error: status?.error.message,
								length: (status?.challenges || [])?.length,
							});
							setSlice(set, state, 'challenges', {
								loading: false,
								error: status?.error.message,
							});
							return;
						}

						if (status?.challenges?.length > 0) {
							setSlice(set, state, 'challenges', {
								data: status.challenges,
								loading: false,
								lastCached: getUTCTimestamp(),
							});
						} else {
							setLoading(set, state, 'challenges', false);
						}
					},

					fetchActiveChallengeDelta: async (
						userEmail?: string,
						bypassCache?: boolean,
					) => {
						const state = get();
						const email = state.user.data?.email || userEmail;

						// Do nothing if user email is not set yet
						if (!email) {
							return;
						}

						// Do nothing if cache is still there
						if (
							state.activeChallenge.lastCached &&
							!checkTimestampExpired(state.activeChallenge.lastCached, 10) &&
							!bypassCache
						) {
							return;
						}

						// Otherwise fetch challenges and set them in the store
						setLoading(set, state, 'activeChallenge', true);
						const status = await getActiveChallenges(email);

						if (status?.error || !status?.challenges) {
							Analytics.track('activeChallenge.get.error', {
								email,
								error: status?.error.message,
								length: (status?.challenges || [])?.length,
							});
							setSlice(set, state, 'activeChallenge', {
								loading: false,
								error: status?.error.message,
							});
							return;
						}

						if (status?.challenges?.length > 0) {
							const activeChallenges = status?.challenges.filter(
								(c) => c.active,
							);

							if (activeChallenges.length > 1) {
								Analytics.track('activeChallenge.get.multiple', {
									email,
									length: activeChallenges.length,
								});
							}

							setSlice(set, state, 'activeChallenge', {
								data: activeChallenges[0],
								loading: false,
								lastCached: getUTCTimestamp(),
							});
						} else {
							setLoading(set, state, 'activeChallenge', false);
						}
					},

					fetchCheckInsDelta: async (
						userEmail?: string,
						bypassCache?: boolean,
					) => {
						const state = get();
						const email = state.user.data?.email || userEmail;

						// Do nothing if user email is not set yet
						if (!email) {
							return;
						}

						// Do nothing if cache is still there
						if (
							state.checkIns.lastCached &&
							!checkTimestampExpired(state.checkIns.lastCached, 10) &&
							!bypassCache
						) {
							return;
						}

						// Otherwise fetch checkIns and set them in the store
						setLoading(set, state, 'checkIns', true);
						const status = await getCheckins(email);
						if (status?.error || !status?.checkIns) {
							Analytics.track('checkIns.get.error', {
								email,
								error: status?.error.message,
								length: (status?.checkIns || [])?.length,
							});
							setSlice(set, state, 'checkIns', {
								loading: false,
								error: status?.error.message,
								lastCached: getUTCTimestamp(),
							});
							return;
						}

						if (status?.checkIns?.length > 0) {
							setSlice(set, state, 'checkIns', {
								data: status.checkIns,
								loading: false,
								lastCached: getUTCTimestamp(),
							});
						} else {
							setSlice(set, state, 'checkIns', {
								loading: false,
								lastCached: getUTCTimestamp(),
							});
						}
					},

					// NOT USED?
					fetchUserDelta: async (userEmail?: string) => {
						const state = get();
						const email = state.user.data?.email || userEmail;

						// Do nothing if user email is not set yet
						if (!email) {
							return;
						}

						// Do nothing if cache is still there
						if (
							state.user.lastCached &&
							!checkTimestampExpired(state.user.lastCached, 1)
						) {
							return;
						}

						setLoading(set, state, 'user', true);
						const { user, error, exists } = await getUser(email);

						// Do nothing if user does not exist
						if (exists === false) {
							setLoading(set, state, 'user', false);
							return;
						}
						if (error) {
							Analytics.track('user.get.error', {
								email,
								error: error.message,
							});
							setSlice(set, state, 'user', {
								loading: false,
								error: error.message,
							});
							return;
						}

						setSlice(set, state, 'user', {
							loading: false,
							data: user,
							lastCached: getUTCTimestamp(),
						});

						return user;
					},

					addLike: (like: Like) =>
						set((state) => ({
							likes: {
								...state.likes,
								data: state.likes.data.find((row) => row.id === like.id)
									? state.likes.data
									: [...state.likes.data, like],
							},
						})),

					upsertUser: async (user: Partial<User> & { email: string }) => {
						const state = get();
						const email = user.email;

						let userInDb = null;
						try {
							const username = generateUsername();
							const userWithUsername = {
								...user,
								username: user.username || username,
							};
							// Create user if doesn't exist
							const { user: newUser, error } =
								await upsertUser(userWithUsername);

							if (error) {
								Analytics.track('user.creation.error', {
									error: error.message,
									email: email,
								});
							}
							if (newUser) {
								userInDb = newUser;
							}
						} catch (error) {
							console.error('Error creating user in db', error);
							Analytics.track('user.creation.error', {
								error: (error as Error).message,
								email,
							});
						}

						if (userInDb?.id) {
							Analytics.identify(userInDb.id);
							state.updateLocalUser(userInDb);
							return userInDb;
						}

						return null;
					},

					updateUser: async (user: Partial<User> & { email: string }) => {
						const state = get();
						const status = await updateUser(user);

						if (status.user) {
							state.updateLocalUser(status.user);
						}
					},

					updateLocalUser: (user: Partial<User>) =>
						set((state) => ({
							user: {
								...(state.user || {}),
								data: {
									...(state.user?.data || {}),
									...user,
								},
							},
						})),

					updateLocalActiveChallenge: (
						challenge: Partial<Challenge>,
						withCache?: boolean,
					) => {
						let meta = {};

						if (withCache) {
							meta = {
								lastCached: getUTCTimestamp(),
							};
						}

						set((state) => ({
							activeChallenge: {
								...(state.activeChallenge || {}),
								...meta,
								data: {
									...(state.activeChallenge?.data || {}),

									// NOTE: Should be partial but types do not alight on required keys
									...(challenge as Challenge),
								},
							},
						}));
					},

					dropCache: (slice: keyof StateType) =>
						set((state) => ({
							[slice]: {
								...state[slice],
								lastCached: null,
							},
						})),

					dropSlice: <T>(slice: keyof StateType, defaultData: T) =>
						set(() => ({
							[slice]: {
								...commonSliceState,
								data: defaultData,
							},
						})),

					clearUser: () =>
						set((state) => ({
							user: {
								...state.user,
								data: null,
							},
						})),
					reset: () => {
						// NOTE: do not set reset (2nd param here to true) as it will prevent the store from having the methods only slice objects
						set(() => defaultInitState);
					},
				}),
				storeConfig,
			),
		),
	);
