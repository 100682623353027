'use client';

import { type ReactNode, createContext, useContext, useRef } from 'react';

import { useStore } from 'zustand';
import {
	type StoreStateActionsType,
	createStore,
	defaultInitState,
} from './index';

export type PropsType = {
	children: ReactNode;
};

export const StoreContext = createContext<ReturnType<
	typeof createStore
> | null>(null);
export const StoreProvider = ({ children }: PropsType) => {
	const storeRef = useRef<ReturnType<typeof createStore>>();
	if (!storeRef.current) {
		storeRef.current = createStore(defaultInitState);
	}

	return (
		<StoreContext.Provider value={storeRef.current}>
			{children}
		</StoreContext.Provider>
	);
};

export function useAppStore<T>(selector: (state: StoreStateActionsType) => T) {
	const store = useContext(StoreContext);
	if (!store) throw new Error('Missing StoreProvider in the tree');
	return useStore(store, selector);
}
